

export const initGA = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
  
    gtag('config', 'G-QEDVP6PRNX');
  };
  
  export const loadGA = () => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-QEDVP6PRNX';
    document.head.appendChild(script);
  
    script.onload = () => {
      initGA();
    };
  };
  