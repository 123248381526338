import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
const Account = React.lazy(()=>import('../screen/account/Account'))
const Login = React.lazy(()=>import('../screen/auth/Login'))
const NotFound = React.lazy(()=>import('../screen/NotFound'))
const QuestionAnswers = React.lazy(()=>import("../screen/question-answer/QuestionAnswer"))
const ProtectedRoute = React.lazy(()=>import('./ProtectedRoute'))
const PrivacyPolicy = React.lazy(()=>import('../screen/policy/PrivacyPolicy'))
const Profile = React.lazy(()=>import('../screen/profile/Profile'))
const Block = React.lazy(()=>import('../screen/Block'))
const FeedBack = React.lazy(()=>import('../screen/feedback/Feedback'))
const SkeletonLoading = React.lazy(()=>(import('../comman/SkeletonLoading')))
const Survey = React.lazy(()=>import("../screen/user/survey/Survey") )


export default function Index() {
  return (
    <BrowserRouter>
    <Suspense fallback ={<SkeletonLoading/>}>
      <Routes>
        {/* <Route path="/pwa/api/save-user-callback" element={<Splash />} /> */}
        <Route
          path="/survey"
          element={
            <ProtectedRoute>
             <Survey/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          }
        />
        <Route
          path="/qns-ans"
          element={
            <ProtectedRoute>
              <QuestionAnswers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/feedback/:survey_id"
          element={
            <ProtectedRoute>
              <FeedBack />
            </ProtectedRoute>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <ProtectedRoute>
              <PrivacyPolicy />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />

        {/* <Route path="/" element={<Splash />} /> */}
        <Route path="/" element={<Login />} />
        <Route path="/block" element={<Block />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
