import { createTheme } from '@mui/material';


export const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: `Montserrat, sans-serif;`
        },
    },
    palette: {
        primary: {
            main: "#071D45",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 7,
                    background:'linear-gradient(124deg, #23DAE6 -13.49%, #0846DA 97.65%)',
                    fontSize:18,
                    textTransform:"none",
                    fontWeight:"normal",
                    padding:10,
               
                },
            },
        },
    },
});

