import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { loginReducer } from "./auth/login";
import { registerReducer } from "./auth/register";
import { addProfileDetailsReducer } from "./profile/addProfileDetails";
import { addUserLocationReducer } from "./user/addUserLocation";
import { completeSurveyReducer } from "./user/completeSurvey";
import { completeSurveyCountReducer } from "./user/completeSurveyCount";
import { appUsesDataReducer } from "./user/defaultQns/appUses";
import { userLocationDataReducer } from "./user/defaultQns/locationData";
import { feedbackReducer } from "./user/feedback";
import { getUserDetailsReducer } from "./user/getUserDetails";
import { pendingSurveyCountReducer } from "./user/pendingSurvey";
import { addSurveyAttmptsReducer } from "./user/qns-ans/addSurveyAttempts";
import { getIncorrectAnsReducer } from "./user/qns-ans/getIncorrectAns";
import { getQnsAnsReducer } from "./user/qns-ans/getQnsAns";
import { qnsCountReducer } from "./user/qns-ans/questionCount";
import { submitQnsAnsReducer } from "./user/qns-ans/submitQnsAns";
import { dropingSurveyReducer } from "./user/survey/dropingSurvey";
import { getSurveyReducer } from "./user/survey/getSurvey";
import { getSurveyOpningTimerReducer } from "./user/survey/getSurveyOpningTime";
import { getUserCompletedSurveyReducer } from "./user/survey/getUserCompletedSurvey";
import { startSurveyReducer } from "./user/survey/startSurvey";
import { surveyReducer } from "./user/survey/survey";
import { userActiveSurveyStatusReducer } from "./user/survey/userActiveSurveyStatus";
import { userCompleteSurveyArrayReducer } from "./user/survey/userCompleteSurveyArray";
import { userRewardToGpReducer } from "./user/userRwardToGP";
import { userSaveCallbackReducer } from "./user/userSaveCallback";
import { userVisibleTaskCountReducer } from "./user/visibleTaskCount";
import { saveLocationReducer } from "./profile/saveLocation";
import { getPublisherDetailsReducer } from "./publisher/getPublisher";
import { getUserCityReducer } from "./user/getUserCity";
import { saveCurrentCityReducer } from "./user/saveCurrentCity";
import { getCompleteSurveyReducer } from "./user/survey/getCompletedSurvey";
import { getAllBannerReducer } from "./user/getAllBanner";

// combine reducer
const rootReducer = combineReducers({
  // auth  -------
  login: loginReducer,
  register: registerReducer,
  addUserLocation: addUserLocationReducer,
  // publisher
  publisherDetails: getPublisherDetailsReducer,
  // user  -------
  saveCurrentCity:saveCurrentCityReducer,
  getUserCity:getUserCityReducer,
  getUserDetails: getUserDetailsReducer,
  pendingSurveyCount: pendingSurveyCountReducer,
  completeSurvey: completeSurveyReducer,
  completeSurveyCount: completeSurveyCountReducer,
  userSaveCallback: userSaveCallbackReducer,
  submitQnsAns: submitQnsAnsReducer,
  feeback: feedbackReducer,
  userVisibleTaskCount: userVisibleTaskCountReducer,
  userRewardToGp: userRewardToGpReducer,
  getAllBanner:getAllBannerReducer,
  // user-->default qns
  userLocationData: userLocationDataReducer,
  appUsesData: appUsesDataReducer,
  // qns ans
  getQnsAns: getQnsAnsReducer,
  qnsCount: qnsCountReducer,
  addSurveyAttmpts: addSurveyAttmptsReducer,
  getIncorrectAns: getIncorrectAnsReducer,
  // survey
  getCompleteSurvey:getCompleteSurveyReducer,
  getSurvey: getSurveyReducer,
  survey: surveyReducer,
  dropingSurvey: dropingSurveyReducer,
  getSurveyOpningTimer: getSurveyOpningTimerReducer,
  userActiveSurveyStatus: userActiveSurveyStatusReducer,
  getUserCompletedSurvey: getUserCompletedSurveyReducer,
  userCompleteSurveyArray: userCompleteSurveyArrayReducer,
  startSurvey: startSurveyReducer,
  // profile
  addProfileDetails: addProfileDetailsReducer,
  saveLocation: saveLocationReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
