import React, { useEffect } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import Routes from "./routes";
import { theme } from "./theme/Theme";
import { ThemeProvider } from "@mui/material";
import { ThemeCntext } from "./context/ThemeCntext";
import { loadGA } from "./services/googleAnalytics";

export default function App() {
 useEffect(()=>{loadGA()},[])
  return (
    
      <ThemeProvider theme={theme}>
        <ThemeCntext>
          <Provider store={store}>
            <Routes />
          </Provider>
        </ThemeCntext>
      </ThemeProvider>
   
  );
}
